import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import Isotope from "isotope-layout";

gsap.registerPlugin(ScrollTrigger);

// Initialisation de Isotope
var iso = new Isotope(".portfolio-scroller", {
  itemSelector: ".item",
  layoutMode: "fitRows",
});

// Gestion des boutons de filtre
var filterButtons = document.querySelectorAll(".filter-button");

filterButtons.forEach(function (button) {
  button.addEventListener("click", function () {
    var filterValue = button.getAttribute("data-filter");

    // Applique le filtre Isotope
    iso.arrange({ filter: filterValue });

    // Mise à jour des classes pour indiquer le bouton actif
    filterButtons.forEach(function (btn) {
      btn.classList.remove("active");
    });
    button.classList.add("active");
  });
});

// Animation GSAP
for (const job of document.querySelectorAll(".reveal")) {
  let video = job.querySelector("video");
  let tween = gsap.to(job, { opacity: 1, duration: 1 });

  ScrollTrigger.create({
    trigger: job,
    start: "75% bottom",
    end: "top top",
    animation: tween,
    onEnter: () => {
      if (video) {
        video.play();
      }
    },
    onEnterBack: () => {
      if (video) {
        video.play();
      }
    },
    onLeave: () => {
      if (video) {
        video.pause();
      }
    },
    onLeaveBack: () => {
      if (video) {
        video.pause();
      }
    },
  });
}

// Burger menu
var openBurger = document.querySelector(".mobile-nav");
var trigger = document.querySelector(".burger");
var cross = document.querySelector(".cross");

var activatBurger = function () {
  openBurger.classList.toggle("is-open");
};

// Open mobile nav when clicking on burger menu
trigger.addEventListener("click", activatBurger);

// Close mobile nav when clicking on the cross icon
cross.addEventListener("click", function () {
  openBurger.classList.remove("is-open");
});
